import type { ChangeEvent, ForwardedRef, KeyboardEvent, ReactElement } from 'react';
import { forwardRef, useId } from 'react';
import styled from 'styled-components';
import type { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { SiteType } from '~/utilities/graphql/codegen';
import { FieldContainer, FieldLabel, FieldError, FieldSuggestion } from '../styles';
import { media } from '../../styles';
import type { Required, Pattern } from '../types';
import { useAppContext } from '~/utilities/context/static/AppContext';

export interface InputFieldProps<TFormValues extends FieldValues> {
  id?: string;
  disabled?: boolean;
  label?: string;
  name: Path<TFormValues>;
  type?: string;
  placeholder?: string;
  error?: string;
  className?: string;
  required?: Required;
  pattern?: Pattern;
  register?: UseFormRegister<TFormValues>;
  setValue?: (name: string, value: unknown) => void;
  onFocus?: () => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  showError?: boolean;
  showSuggestion?: boolean;
  setShowSuggestion?: (suggested: boolean) => void;
  suggestionText?: string;
  suggestionValue?: string;
  separate?: boolean;
  testId?: string;
  [k: string]: unknown;
  value?: string;
  role?: string;
  ariaExpanded?: boolean;
}

const S = {
  InputWrapper: styled.div``,

  Input: styled.input<{ $error: string; $isGStar: boolean }>`
    height: ${({ theme }) => (theme.siteType === SiteType.Aaf ? '50px' : '48px')};
    font-weight: ${({ theme }) => (theme.siteType === SiteType.Aaf ? 400 : 700)};
    padding: ${({ $isGStar }) => ($isGStar ? '11px 10px 10px' : ({ theme }) => theme.size[4])};
    color: ${({ theme }) => theme.colors.content.primary};
    font-size: 13px;
    border: ${({ theme }) => (theme.siteType === SiteType.Aaf ? '1px' : '2px')} solid
      ${({ $error }) =>
        $error
          ? ({ theme }) => theme.colors.border.error
          : ({ theme }) => theme.colors.border.base};
    background: ${({ theme }) => theme.colors.background.base};
    line-height: normal;
    outline: 0;
    display: inline-block;
    width: 100%;

    &:focus {
      border-color: ${({ $error }) =>
        $error
          ? ({ theme }) => theme.colors.border.error
          : ({ theme }) => theme.colors.border.focused};
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.content.tertiary};
      font-weight: ${({ $error }) => ($error ? 700 : 400)};
    }

    &:focus::placeholder {
      color: ${({ theme }) => theme.colors.background.component.shared.focus};
    }

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      height: ${({ theme }) => (theme.siteType === SiteType.Aaf ? '50px' : '40px')};
    }
  `,

  FieldError: styled(FieldError)<{ $separate: boolean }>`
    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      padding-left: ${({ $separate }) => ($separate ? '26%' : 0)};
    }
  `,
  FieldSuggestion: styled(FieldSuggestion)<{ $separate: boolean }>`
    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      padding-left: ${({ $separate }) => ($separate ? '26%' : 0)};
    }
  `,
};

const InputFieldComponent = <TFormValues extends FieldValues>(
  {
    id,
    disabled,
    label,
    name,
    type = 'text',
    placeholder = '',
    error = '',
    className = '',
    required,
    pattern,
    register,
    setValue,
    onFocus,
    onBlur,
    value,
    onChange,
    showError,
    showSuggestion,
    setShowSuggestion,
    suggestionText,
    suggestionValue,
    onKeyDown,
    onKeyUp,
    autoComplete,
    separate = false,
    testId = undefined,
    role,
    ariaExpanded,
  }: InputFieldProps<TFormValues>,
  ref?: ForwardedRef<HTMLInputElement>
): ReactElement => {
  const errorMessageId = `error-message${useId()}`;
  const { isGStar } = useAppContext();

  return (
    <>
      <FieldContainer className={className}>
        {label && <FieldLabel htmlFor={name}>{label}</FieldLabel>}
        <S.InputWrapper className="input-field-wrapper">
          <S.Input
            $isGStar={isGStar}
            id={id}
            disabled={disabled}
            type={type}
            placeholder={placeholder}
            $error={error}
            {...(register ? register(name, { required, pattern }) : { ref })}
            {...(onFocus ? { onFocus } : {})}
            {...(onBlur ? { onBlur } : {})}
            {...(onChange ? { onChange } : {})}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            autoComplete={autoComplete}
            data-testid={error ? `${testId}-error` : testId}
            value={value}
            role={role}
            aria-invalid={error ? true : undefined}
            aria-describedby={error ? errorMessageId : undefined}
            aria-expanded={role === 'combobox' ? ariaExpanded : undefined}
          />
        </S.InputWrapper>
      </FieldContainer>
      {error && showError && (
        <S.FieldError
          id={errorMessageId}
          $separate={separate}
          data-testid={`${testId}-error-message`}
        >
          {error}
        </S.FieldError>
      )}
      {!error &&
        showSuggestion &&
        setShowSuggestion &&
        suggestionText &&
        setValue &&
        suggestionValue && (
          <S.FieldSuggestion variant="body" component="span" $separate={separate}>
            {suggestionText}{' '}
            <strong
              aria-hidden="true"
              onClick={() => {
                setValue('email', suggestionValue);
                setShowSuggestion(false);
              }}
              onKeyDown={() => {
                setValue('email', suggestionValue);
                setShowSuggestion(false);
              }}
            >
              {suggestionValue}
            </strong>
          </S.FieldSuggestion>
        )}
    </>
  );
};

export const InputField = forwardRef(InputFieldComponent);
