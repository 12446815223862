import type { ReactElement } from 'react';
import { useId } from 'react';
import styled from 'styled-components';
import { media } from '../../styles';
import { Typography } from '../../typography/Typography';
import { FieldError } from '../styles';

export interface RadioGroupProps {
  radios: ReactElement[];
  className?: string;
  error?: string;
  label: string;
  testId?: string;
  role?: string;
}

const S = {
  Container: styled.div`
    margin-bottom: 0;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      margin-bottom: 14px;
    }
  `,

  Wrapper: styled.div`
    display: flex;
    flex-direction: column;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      align-items: center;
      flex-direction: row;
    }
  `,

  Label: styled(Typography)`
    line-height: 24px;
    padding: 5px 0;
    display: block;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      margin-right: 20px;
    }
  `,
};

export const RadioGroup = ({
  radios,
  className = '',
  error,
  label,
  testId = undefined,
  role,
}: RadioGroupProps): ReactElement => {
  const radiogroupLabelId = `rg-label${useId()}`;
  const radiogroupErrorId = `rg-error${useId()}`;

  return (
    <S.Container>
      <S.Wrapper
        aria-labelledby={radiogroupLabelId}
        aria-invalid={error ? true : undefined}
        aria-describedby={error ? radiogroupErrorId : undefined}
        className={className}
        data-testid={testId}
        role={role}
      >
        <S.Label id={radiogroupLabelId} component="span" variant="h5">
          {label}
        </S.Label>
        {radios.map(radio => radio)}
      </S.Wrapper>
      {error && (
        <FieldError id={radiogroupErrorId} data-testid={`${testId}-error`}>
          {error}
        </FieldError>
      )}
    </S.Container>
  );
};
