import styled from 'styled-components';
import { Typography } from '../typography/Typography';

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const FieldLabel = styled.label`
  margin-bottom: 5px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.content.primary};
  line-height: normal;
  font-size: 13px;
  cursor: pointer;
`;

export const FieldError = styled.div`
  color: ${({ theme }) => theme.colors.content.error};
  font-size: 13px;
  line-height: 1.4;
  padding-top: 4px;
  width: 100%;
  margin-bottom: 6px;
`;

export const FieldSuggestion = styled(Typography)`
  padding-top: 4px;
  margin-bottom: 6px;

  strong {
    font-weight: 700;
    cursor: pointer;
  }
`;
