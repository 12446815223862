/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import type { PropsWithChildren } from 'react';
import { storageAvailable } from '../../helpers';
import { useMediaQueryContext } from './MediaQueryContext';
import { useWindowSize } from '../../dom';
import { sideNavigationScript } from '../../dom/script';
import { useAppContext } from '~/utilities/context/static/AppContext';

const hasOpenSideNavigation = (defaultValue = true) => {
  let value = defaultValue;

  if (storageAvailable('sessionStorage')) {
    const storageValue = sessionStorage.getItem('hasOpenSideNavigation');

    if (storageValue !== null) {
      value = storageValue === 'true';
    }
  }

  return value;
};

type NavActionContextType = {
  sideNavOpened: boolean;
  setSideNavOpened: (val: boolean) => void;

  flyoutItem: string;
  setFlyoutItem: (val: string) => void;

  closeSideNav: () => void;
  toggleSideNav: () => void;
};

const NavActionContext = createContext<NavActionContextType>({
  sideNavOpened: false,
  setSideNavOpened: () => {},
  flyoutItem: '',
  setFlyoutItem: () => {},
  closeSideNav: () => {},
  toggleSideNav: () => {},
});

export const useNavActionContext = () => useContext(NavActionContext);

// TODO: identify and refactor why 2 CSS classes are used for side nav state
export const NavActionContextProvider = ({
  children,
  defaultValue,
}: PropsWithChildren<{ defaultValue?: boolean }>) => {
  const { isLargeDesktop } = useMediaQueryContext();

  const initialState = isLargeDesktop ? hasOpenSideNavigation(defaultValue !== false) : false;

  const [sideNavOpened, setSideNavOpened] = useState(initialState);
  const [flyoutItem, setFlyoutItem] = useState('');
  const { width } = useWindowSize();
  const { isGStar } = useAppContext();
  const isMobileSize = width < 768;
  const desktopSideNavWidth = isGStar ? '240px' : '250px';
  let mobileSideNavWidth = '314px';

  if (isGStar) {
    if (width < 300) {
      mobileSideNavWidth = '240px';
    }

    if (width > 300 && width < 400) {
      mobileSideNavWidth = `${width * 0.8}px`;
    }

    if (width > 400) {
      mobileSideNavWidth = '320px';
    }
  }

  const sideNavWidth = isMobileSize ? mobileSideNavWidth : desktopSideNavWidth;

  const closeSideNav = useCallback(() => {
    setSideNavOpened(false);

    if (storageAvailable('sessionStorage')) {
      sessionStorage.setItem('hasOpenSideNavigation', 'false');
    }
  }, []);

  const toggleSideNav = useCallback(() => {
    const sideNavToggled = !sideNavOpened;

    setSideNavOpened(sideNavToggled);

    if (storageAvailable('sessionStorage')) {
      sessionStorage.setItem('hasOpenSideNavigation', `${sideNavToggled}`);
    }
  }, [sideNavOpened]);

  const value = useMemo(
    () => ({
      sideNavOpened,
      setSideNavOpened,
      flyoutItem,
      setFlyoutItem,
      closeSideNav,
      toggleSideNav,
    }),
    [sideNavOpened, flyoutItem, closeSideNav, toggleSideNav]
  );

  useEffect(() => {
    if (sideNavOpened && !isLargeDesktop) {
      document.body.style.setProperty('overflow', 'hidden');
    } else {
      document.body.style.setProperty('overflow', 'auto');
    }
  }, [isLargeDesktop, sideNavOpened]);

  useEffect(() => {
    if (sideNavOpened) {
      document.documentElement.classList.add('has-open-sideNavigation');
      document.documentElement.style.setProperty('--side-nav-width', sideNavWidth);
    } else {
      document.documentElement.classList.remove('has-open-sideNavigation');
      document.documentElement.style.setProperty('--side-nav-width', '0px');
    }
  }, [sideNavOpened, sideNavWidth]);

  useEffect(() => {
    if (isLargeDesktop) {
      setSideNavOpened(hasOpenSideNavigation(defaultValue !== false));
    } else {
      setSideNavOpened(false);
    }
  }, [isLargeDesktop, setSideNavOpened, defaultValue]);

  return (
    <>
      <script
        suppressHydrationWarning
        /* eslint-disable react/no-danger */
        dangerouslySetInnerHTML={{
          __html: `(${sideNavigationScript.toString()})(${defaultValue}, "${sideNavWidth}")`,
        }}
      />
      <NavActionContext.Provider value={value}>{children}</NavActionContext.Provider>
    </>
  );
};
