import { useCallback, useEffect } from 'react';
import aa from 'search-insights';
import type { InsightsMethodMap } from 'search-insights';
import type { Maybe } from 'graphql/jsutils/Maybe';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { storageAvailable } from '../../../utilities/helpers';
import { useCookieContext } from '../../../utilities/context/dynamic/CookieContext';
import { useUserContext } from '../../../utilities/context/dynamic/UserContext';

type pushToAlgoliaFn = (
  event: keyof InsightsMethodMap,
  eventName: string,
  objectId: Maybe<string>,
  includeQueryId?: boolean
) => void;

type pushToAlgoliaWithQueryIdFn = (
  event: keyof InsightsMethodMap,
  eventName: string,
  eventData: {
    objectIDs?: Array<string>;
    filters?: Array<string>;
    positions?: Array<number>;
    index?: string;
    queryID?: string;
    timestamp?: number;
  }
) => void;

export const useAlgoliaInsights = () => {
  const {
    configuration: {
      algoliaAppId: appId,
      algoliaInsightsApiKey: apiKey,
      algoliaProductPrimaryIndexName: indexFromConfig,
      enableCookieWall,
    },
  } = useStaticContext();
  const { commonData } = useUserContext();
  const { imanrut, rsu, cocoCookie } = useCookieContext();
  const cocoMinimumConsent = cocoCookie?.toString().endsWith('111');
  const cookieAnalyticsApproval = !enableCookieWall || cocoMinimumConsent;
  const shouldReportAnalytics =
    !!rsu && !imanrut && !commonData?.internal && cookieAnalyticsApproval;

  useEffect(() => {
    if (indexFromConfig && storageAvailable('localStorage')) {
      localStorage.setItem('algoliaIndexName', indexFromConfig);
    }
  }, [indexFromConfig]);

  const readStorage = () => {
    const index = storageAvailable('localStorage')
      ? (localStorage.getItem('algoliaIndexName') as Maybe<string>)
      : '';
    const queryID = storageAvailable('localStorage')
      ? (localStorage.getItem('algoliaQueryID') as Maybe<string>)
      : '';

    return { index, queryID };
  };

  const pushToAlgolia = useCallback<pushToAlgoliaFn>(
    (event, eventName, objectId, includeQueryId) => {
      const { index, queryID } = readStorage();
      const hasAnalyticsData = !!(objectId && eventName && (index || indexFromConfig));

      if (!(shouldReportAnalytics && hasAnalyticsData)) {
        return;
      }

      const aaEventData = {
        userToken: rsu,
        index: index ?? indexFromConfig,
        eventName,
        objectIDs: [objectId],
        ...(includeQueryId && queryID ? { queryID } : {}),
      };

      aa(event, aaEventData);
    },
    [indexFromConfig, shouldReportAnalytics, rsu]
  );

  const pushToAlgoliaWithQueryId = useCallback<pushToAlgoliaWithQueryIdFn>(
    (event, eventName, eventData) => {
      const { index, queryID } = readStorage();
      const hasAnalyticsData = !!(
        eventData &&
        eventName &&
        (eventData.index || index || indexFromConfig)
      );

      const trackedQueryID = eventData.queryID || queryID;

      if (!(shouldReportAnalytics && hasAnalyticsData)) {
        return;
      }

      const aaEventData = {
        userToken: rsu,
        index: index ?? indexFromConfig,
        eventName,
        ...eventData,
      };

      if (trackedQueryID) {
        aaEventData.queryID = trackedQueryID;
      }

      aa(event, aaEventData);
    },
    [indexFromConfig, rsu, shouldReportAnalytics]
  );

  const initialize = useCallback(() => {
    if (shouldReportAnalytics && appId && apiKey) {
      aa('init', {
        appId,
        apiKey,
        userToken: rsu,
        useCookie: cookieAnalyticsApproval,
        userHasOptedOut: !cookieAnalyticsApproval,
      });
    }
  }, [cookieAnalyticsApproval, apiKey, appId, rsu, shouldReportAnalytics]);

  return { pushToAlgolia, initialize, pushToAlgoliaWithQueryId };
};
