import type { HotspotsImage } from '~/amplienceTypes/content';
import type { Images1 as Images } from '~/amplienceTypes/partials/exported/responsive-image-schema';

export const hasHotspots = (images: Images) => {
  const { mobile, tablet, desktop }: HotspotsImage = images;

  return (
    mobile?.metadata?.metadata?.hotSpots?.hotSpots?.list ||
    tablet?.metadata?.metadata?.hotSpots?.hotSpots?.list ||
    desktop?.metadata?.metadata?.hotSpots?.hotSpots?.list
  );
};
