import { css, keyframes } from 'styled-components';
import { colors, media } from '../core/styles';

const shimmer = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
`;

export const shimmerAnimation = css`
  overflow: hidden;

  &::after {
    user-select: none;
    pointer-events: none;
    width: 100%;
    height: 100%;
    display: block;
    background-image: linear-gradient(
      90deg,
      rgba(255 255 255 / 0%) 0,
      rgba(255 255 255 / 40%) 40%,
      rgba(255 255 255 / 90%) 60%,
      rgba(255 255 255 / 0%)
    );

    @media ${({ theme }) => media(theme).noMotionPreference} {
      content: '\00A0';
      will-change: transform;
      animation: ${shimmer} 2s infinite;
    }
  }
`;

export const a11yInvisibleStyle = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
`;

export const hideScrollBar = css`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const sizeButtonStyles = {
  large: {
    fontSize: '14px',
    width: '68px',
    height: '68px',
    marginRight: '4px',
    marginBottom: '4px',
    direction: 'column',
    disabledBackgroundMobile: `repeating-linear-gradient(45deg,
      transparent,transparent 46.5px,
      ${colors.DISABLED_GREY} 47.5px,${colors.DISABLED_GREY} 23.5px)`,
    disabledBackground: `repeating-linear-gradient(45deg,
        transparent,transparent 46.5px,
        ${colors.BALI_HAI_GREY} 47.5px,${colors.BALI_HAI_GREY} 23.5px)`,
  },
  medium: {
    fontSize: '14px',
    width: '58px',
    height: '58px',
    marginRight: '4px',
    marginBottom: '4px',
    direction: 'column',
    disabledBackgroundMobile: `repeating-linear-gradient(45deg,
      transparent,transparent 38.5px,
      ${colors.DISABLED_GREY} 39.5px,${colors.DISABLED_GREY} 23.5px)`,
    disabledBackground: `repeating-linear-gradient(45deg,
      transparent,transparent 38.5px,
      ${colors.BALI_HAI_GREY} 39.5px,${colors.BALI_HAI_GREY} 23.5px)`,
  },
  fourA: {
    fontSize: '13px',
    width: '58px',
    height: '58px',
    marginRight: '4px',
    marginBottom: '4px',
    direction: 'row',
    disabledBackgroundMobile: `repeating-linear-gradient(45deg,
      transparent,transparent 39.5px,
      ${colors.DISABLED_GREY} 40.5px,${colors.DISABLED_GREY} 23.5px)`,
    disabledBackground: `repeating-linear-gradient(45deg,
      transparent,transparent 39.5px,
      ${colors.BALI_HAI_GREY} 40.5px,${colors.BALI_HAI_GREY} 23.5px)`,
  },
  normal: {
    fontSize: '13px',
    width: '40px',
    height: '40px',
    marginRight: '4px',
    marginBottom: '4px',
    direction: 'column',
    disabledBackgroundMobile: `repeating-linear-gradient(45deg,
      transparent,transparent 25.5px,
      ${colors.DISABLED_GREY} 26.5px,${colors.DISABLED_GREY} 26.5px)`,
    disabledBackground: `repeating-linear-gradient(45deg,
      transparent,transparent 25.5px,
      ${colors.BALI_HAI_GREY} 26.5px,${colors.BALI_HAI_GREY} 26.5px)`,
  },
  sizeRange: {
    fontSize: '13px',
    width: 'fit-content',
    height: '40px',
    marginRight: '4px',
    marginBottom: '4px',
    paddingRight: '11px',
    paddingLeft: '11px',
    direction: 'column',
    disabledBackground: `linear-gradient(to bottom left, transparent 49%, ${colors.BALI_HAI_GREY} 50%, ${colors.BALI_HAI_GREY} 50%, transparent 51%)`,
    disabledBackgroundMobile: `linear-gradient(to bottom left, transparent 49%, ${colors.DISABLED_GREY} 50%, ${colors.DISABLED_GREY} 50%, transparent 51%)`,
  },
};
