import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import type { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { SiteType } from '~/utilities/graphql/codegen';
import type { Required } from '../types';
import { getSiteStyles } from '~/utilities/helpers';

export interface CheckboxProps<TFormValues extends FieldValues> {
  label: string | ReactElement;
  name: Path<TFormValues>;
  error?: string;
  className?: string;
  register: UseFormRegister<TFormValues>;
  required?: Required;
  testId?: string;
  isChecked?: 'true' | 'false';
  [k: string]: unknown;
}

const S = {
  CheckboxLabel: styled.label`
    ${({ theme }) => css`
      padding: 14px 0;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      line-height: 24px;
      color: ${theme.colors.content.primary};
    `}
  `,

  Checkbox: styled.input<{ $error: string }>`
    ${({ theme, $error }) => css`
      position: absolute;
      left: -100000px;

      & + span::before {
        content: '';
      }

      &:checked + span::before {
        font-family: gstar-icons;
        content: '\\F13C';
        color: ${theme.colors.content.primary};
      }

      &:focus + span::before {
        border: ${theme.borders.width.sm} solid
          ${$error ? theme.colors.border.error : theme.colors.border.base};
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          padding: 16px 0 0;
        `,
        [SiteType.Aaf]: css`
          & + span::before {
            border-width: ${theme.borders.width.xs} !important;
          }

          &:checked + span::before {
            content: '\\F253';
          }
        `,
      })}
    `}
  `,

  Label: styled.span<{ $error: string }>`
    ${({ theme, $error }) => css`
      padding: 5px 10px 5px 36px;
      display: flex;
      align-items: center;
      font-weight: 400;
      box-sizing: border-box;
      vertical-align: middle;
      line-height: 1.3;
      font-size: 13px;
      color: ${theme.colors.content.primary};

      &::before {
        margin-top: 0;
        font-size: 18px;
        background-color: transparent;
        border: ${theme.borders.width.sm} solid
          ${$error ? theme.colors.border.error : theme.colors.border.base};
        display: block;
        font-weight: 400;
        line-height: 20px;
        position: absolute;
        content: '';
        text-align: center;
        width: 22px;
        height: 22px;
        left: 0;
        box-sizing: content-box;
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          padding: 0 0 0 30px;
          font-size: ${theme.font.size[2]};
        `,
        [SiteType.Aaf]: css`
          border-width: ${theme.borders.width.xs};
          align-items: start;
          font-size: ${theme.font.size[2]};

          &::before {
            border-width: ${theme.borders.width.xs};
          }
        `,
      })}
    `}
  `,
};

export const Checkbox = <TFormValues extends FieldValues>({
  label,
  name,
  error = '',
  className = '',
  register,
  required,
  testId = undefined,
}: CheckboxProps<TFormValues>): ReactElement => (
  <S.CheckboxLabel className={className} data-testid={testId}>
    <S.Checkbox $error={error} type="checkbox" {...register(name, { required })} />
    <S.Label $error={error}>{label}</S.Label>
  </S.CheckboxLabel>
);
