import type { ReactElement } from 'react';
import { gsPartialBannerHeight } from '../../../utilities/constants/homepageHandlebars';
import Attributes from '../partials/Attributes';
import type { ContentBlockDataSchema } from '../partials/ContentBlock';
import { ContentBlock } from '../partials/ContentBlock';
import type { Title as SimpleVideoBannerSchema } from '../../../amplienceTypes/schemas/exported/simple-video-banner-schema';
import type { Title as SimpleBannerSchema } from '../../../amplienceTypes/schemas/exported/simple-banner-schema';
import type { Title as BannerSchema } from '../../../amplienceTypes/schemas/exported/banner-schema';
import type { Title as LocalizedBanner } from '../../../amplienceTypes/schemas/exported/localized-banner-schema';
import type { Title as LogoBanner } from '../../../amplienceTypes/schemas/exported/logo-banner-schema';
import type { Title as HotspotBanner } from '../../../amplienceTypes/schemas/exported/hotspot-banner-schema';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';

type SchemaDataForBanners =
  | SimpleVideoBannerSchema
  | SimpleBannerSchema
  | BannerSchema
  | LocalizedBanner
  | LogoBanner
  | HotspotBanner;

export const Banner = ({
  attributes,
  height,
  contentBlock,
  ...props
}: SchemaDataForBanners): ReactElement => {
  const contentBlockData = contentBlock as ContentBlockDataSchema;
  const { accessibilityLabels } = useStaticContext();

  return contentBlock ? (
    <Attributes
      {...props}
      {...attributes}
      additionalClassNames={`row ${height ? gsPartialBannerHeight[height] : ''}`}
      ariaRole={accessibilityLabels?.hpBanner?.role}
      ariaLabel={accessibilityLabels?.hpBanner?.label}
    >
      <div className="col col--sm12 col--md12">
        <ContentBlock {...contentBlockData} />
      </div>
    </Attributes>
  ) : (
    <></>
  );
};
