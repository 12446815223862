export const sideNavigationScript = (defaultOpenValue: boolean, sideNavWidth: string) => {
  try {
    if (window !== undefined) {
      if (
        defaultOpenValue === false ||
        sessionStorage.getItem('hasOpenSideNavigation') === 'false'
      ) {
        document.documentElement.classList.remove('has-open-sideNavigation');
        document.documentElement.style.setProperty('--side-nav-width', '0px');
      } else {
        document.documentElement.classList.add('has-open-sideNavigation');
        document.documentElement.style.setProperty('--side-nav-width', sideNavWidth);
      }
    }
  } catch (e) {}
};

export const highstreetScript = () => {
  try {
    if (window !== undefined && window.navigator.userAgent.includes('Highstreet')) {
      document.documentElement.classList.add('is-highstreet');
    }
  } catch (e) {}
};
