import type { ReactElement } from 'react';
import styled from 'styled-components';
import type { Button as ButtonSchema } from '../../../amplienceTypes/partials/exported/button-schema';

const S = {
  Link: styled.a`
    && {
      &.contentBlock-body-link {
        margin: 0 4px 8px;
        font-size: ${({ theme }) => theme.font.size[3]};
        pointer-events: auto;
      }

      .contentBlock--clickable & {
        &:nth-child(n + 3) {
          display: none;
        }
      }
    }
  `,
};

export const Button = ({ url, target, text, ariaLabel }: ButtonSchema): ReactElement => {
  const ariaLabelProp = ariaLabel ? { 'aria-label': ariaLabel } : {};

  return (
    <S.Link
      href={url ?? ''}
      className="contentBlock-body-link js-contentBlock-body-link"
      target={`${target === 'New Window/Tab' ? '_blank' : '_self'}`}
      {...ariaLabelProp}
    >
      <span className="gstar-icon icon-link contentBlock-body-link-icon" />
      <span className="contentBlock-body-link-label">{text}</span>
    </S.Link>
  );
};
