import type { ReactElement, ChangeEvent, KeyboardEvent } from 'react';
import styled, { css } from 'styled-components';
import type { RuleSet } from 'styled-components';
import { colors } from '../../styles';
import { SiteType } from '~/utilities/graphql/codegen';

interface SwitchProps {
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  onKeyDown?: (event: KeyboardEvent) => void;
}

const GStarLabelStyles = css<{ $checked: boolean }>`
  background-color: ${({ $checked }) => ($checked ? colors.SUCCESS_BLUE : colors.WHITE_SMOKE_GREY)};
`;

const AafLabelStyles = css<{ $checked: boolean }>`
  background-color: ${({ $checked }) => ($checked ? '#0d0d0b' : colors.WHITE_SMOKE_GREY)};
`;

const LabelConfig: Record<SiteType, RuleSet<{ $checked: boolean }>> = {
  [SiteType.Gstar]: GStarLabelStyles,
  [SiteType.Outlet]: GStarLabelStyles,
  [SiteType.Employeeshop]: GStarLabelStyles,
  [SiteType.Aaf]: AafLabelStyles,
};

const S = {
  Input: styled.input`
    display: none;
  `,

  Label: styled.label<{ $checked: boolean }>`
    border: 1px solid;
    border-color: ${({ $checked }) => ($checked ? 'transparent' : colors.ACCESSIBILITY_GREY)};
    border-radius: 50px;
    cursor: pointer;
    height: 24px;
    width: 48px;
    position: relative;
    display: block;
    ${({ theme }) => LabelConfig[theme.siteType]}
  `,

  Switch: styled.span<{ $checked: boolean }>`
    background-color: ${colors.WHITE};
    border: 1px solid ${colors.NERO_GREY};
    border-radius: 50px;
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
    top: -1px;
    left: ${({ $checked }) => ($checked ? 'calc(100% - 25px)' : '-1px')};
    transition: left 0.25s ease-in;

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,
};

export const Switch = ({ className, onChange, checked, onKeyDown }: SwitchProps): ReactElement => (
  <S.Label className={className} $checked={checked} onKeyDown={onKeyDown} tabIndex={0}>
    <S.Input type="checkbox" onChange={onChange} />
    <S.Switch $checked={checked} />
  </S.Label>
);
