import type { ChangeEvent, ReactElement } from 'react';
import { useEffect, useState } from 'react';
import type { RuleSet } from 'styled-components';
import styled, { css } from 'styled-components';
import type { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { SiteType } from '~/utilities/graphql/codegen';
import { FieldContainer, FieldLabel, FieldError } from '../styles';
import { colors } from '../../styles';
import type { Required } from '../types';
import CheckIcon from '../../../core/icons/CheckIcon';

export interface Option {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface SelectProps<TFormValues extends FieldValues> {
  name: Path<TFormValues>;
  label?: string;
  options?: Option[];
  placeholder?: string;
  error?: string;
  ariaLabel?: string;
  children?: ReactElement[];
  className?: string;
  register?: UseFormRegister<TFormValues>;
  required?: Required;
  ordinal?: 'primary' | 'secondary';
  defaultValue?: string | number;
  value?: string | number;
  overflowOption?: boolean;
  showError?: boolean;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  testId?: string;
  [k: string]: unknown;
}

function optionsRenderer(options: Option[]): ReactElement[] {
  return options.map(({ value, label, disabled }: Option) => (
    <option key={value} value={value} disabled={disabled}>
      {label}
    </option>
  ));
}

const styles = {
  select: css<{ $error: string }>`
    font-size: 13px;
    font-weight: 700;
    outline: 0;
    appearance: none;
    -webkit-appearance: none; /* stylelint-disable-line value-no-vendor-prefix, property-no-vendor-prefix */
    -moz-appearance: none; /* stylelint-disable-line value-no-vendor-prefix, property-no-vendor-prefix */
    color: ${({ $error }) =>
      $error
        ? ({ theme }) => theme.colors.content.error
        : ({ theme }) => theme.colors.background.inverse};

    &:focus,
    &:focus-visible {
      border-color: ${({ theme }) => theme.colors.border.focused};
    }

    option {
      color: ${({ theme }) => theme.colors.background.inverse};
    }
  `,

  GStarSelect: css<{ $error: string; disabled: boolean }>`
    border: 1px solid
      ${({ $error, disabled }) => {
        if (disabled) {
          return ({ theme }) => theme.colors.border.mid;
        }

        if ($error) {
          return ({ theme }) => theme.colors.border.error;
        }

        return ({ theme }) => theme.colors.border.component.select.light;
      }};
    background: ${({ disabled, $error }) =>
      $error
        ? ({ theme }) => theme.colors.effect.error
        : ({ theme }) =>
            theme.colors.background.base + (!disabled && ` var(--arrow-down) no-repeat right`)};

    &:focus,
    &:focus-visible {
      border: 2px solid;
      background: var(--arrow-down-dark) no-repeat right;
    }
  `,

  OutletSelect: css<{ $error: string }>`
    padding: ${({ theme }) => theme.size[4]};
    color: ${({ $error }) =>
      $error
        ? ({ theme }) => theme.colors.content.error
        : ({ theme }) => theme.colors.background.base};
    border: 2px solid
      ${({ $error }) =>
        $error
          ? ({ theme }) => theme.colors.border.error
          : ({ theme }) => theme.colors.border.inverse};
    background: ${({ $error }) =>
        $error
          ? ({ theme }) => theme.colors.effect.error
          : ({ theme }) => theme.colors.background.inverse}
      var(--arrow-down-dark) no-repeat right;
  `,

  AafSelect: css<{ $error: string }>`
    padding: ${({ theme }) => theme.size[3]};
    border: 1px solid
      ${({ $error }) =>
        $error
          ? ({ theme }) => theme.colors.border.error
          : ({ theme }) => theme.colors.border.component.select.light};
    background: ${({ $error }) =>
        $error
          ? ({ theme }) => theme.colors.effect.error
          : ({ theme }) => theme.colors.background.base}
      var(--aaf-arrow-down) no-repeat;
    background-position: right ${({ theme }) => theme.size[4]} center;
  `,

  GStarPrimary: css<{ $error: string }>`
    padding: 12px 32px 12px 14px;
  `,

  GStarSecondary: css<{ $error: string }>`
    padding: 11px 40px 11px 10px;
  `,

  GStarOption: css<{ $error: string }>`
    padding: 0 40px 0 10px;
    border: 2px solid
      ${({ $error }) =>
        $error
          ? ({ theme }) => theme.colors.content.error
          : ({ theme }) => theme.colors.background.inverse};
  `,

  AafOption: css<{ $error: string }>`
    padding: 4px 40px 4px 10px;
    border: 1px solid
      ${({ $error }) =>
        $error
          ? ({ theme }) => theme.colors.content.error
          : ({ theme }) => theme.colors.background.inverse};
  `,
};

export const SelectStyle: Record<SiteType, RuleSet<{ $error: string; disabled: boolean }>> = {
  [SiteType.Gstar]: styles.GStarSelect,
  [SiteType.Outlet]: styles.OutletSelect,
  [SiteType.Employeeshop]: styles.GStarSelect,
  [SiteType.Aaf]: styles.AafSelect,
};

export const OptionStyle: Record<
  SiteType,
  RuleSet<{ $error: string; selected: string | number }>
> = {
  [SiteType.Gstar]: styles.GStarOption,
  [SiteType.Outlet]: styles.GStarOption,
  [SiteType.Employeeshop]: styles.GStarOption,
  [SiteType.Aaf]: styles.AafOption,
};

const S = {
  Select: styled.select<{ $ordinal: string; $error: string; disabled: boolean }>`
    ${styles.select}
    ${({ theme }) => SelectStyle[theme.siteType]}
    
    &.select-primary {
      ${({ theme }) => theme.siteType === SiteType.Gstar && styles.GStarPrimary}
    }

    &.select-secondary {
      ${({ theme }) => theme.siteType === SiteType.Gstar && styles.GStarSecondary}
    }
  `,

  Option: styled.div<{ $error: string; selected: string | number }>`
    ${({ theme }) => OptionStyle[theme.siteType]}
    position: absolute;
    bottom: 0;
    line-height: 36px;
    box-sizing: border-box;
    width: 100%;
    pointer-events: none;
    background: ${({ selected, $error }) => {
      let background = `${colors.WHITE} var(--arrow-down-dark) no-repeat right`;

      if ($error && selected) {
        background = colors.ORANGE_YELLOW;
      } else if ($error && !selected) {
        background = `${colors.ORANGE_YELLOW} var(--arrow-down-dark) no-repeat right`;
      } else if (selected) {
        background = colors.WHITE;
      }

      return background;
    }};

    span {
      color: ${({ $error }) =>
        $error
          ? ({ theme }) => theme.colors.content.error
          : ({ theme }) => theme.colors.background.inverse};
    }
  `,

  Placeholder: styled.span`
    max-width: 60px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;
    font-size: 13px;
    text-transform: lowercase;
  `,

  Value: styled.span`
    position: absolute;
    padding-left: 15px;
    font-weight: 700;
    font-size: 13px;
  `,

  CheckIcon: styled.div`
    position: absolute;
    top: calc(50% - ${({ theme }) => (theme.siteType === SiteType.Aaf ? '10px' : '12px')});
    right: 10px;
    width: 10px;
    height: 10px;
  `,
};

export const Select = <TFormValues extends FieldValues>({
  name,
  label,
  options = [],
  error = '',
  ariaLabel = 'select-dropdown',
  children,
  className = '',
  register,
  required,
  onChange,
  ordinal = 'primary',
  defaultValue = '',
  value,
  overflowOption,
  placeholder,
  showError,
  disabled = false,
  testId = undefined,
}: SelectProps<TFormValues>): ReactElement => {
  const childrenToRender = children || optionsRenderer(options);
  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    if (value !== undefined) {
      setSelected(value);
    }
  }, [value]);

  return (
    <FieldContainer className={className}>
      {label && <FieldLabel htmlFor={name}>{label}</FieldLabel>}
      <S.Select
        aria-label={ariaLabel}
        defaultValue={defaultValue || ''}
        {...(register ? register(name, { required }) : {})}
        $ordinal={ordinal}
        className={`select-${ordinal}`}
        disabled={disabled}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          if (onChange) {
            onChange(e);
          }

          setSelected(e.target.value);
        }}
        $error={error}
        data-testid={error ? `${testId}-error` : testId}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {childrenToRender}
      </S.Select>
      {overflowOption && (
        <>
          <S.Option $error={error} selected={selected} className="overflow-option">
            <S.Placeholder>{placeholder}</S.Placeholder>
            {selected && <S.Value>{selected}</S.Value>}
          </S.Option>
          {selected && (
            <S.CheckIcon>
              <CheckIcon color={colors.NERO_GREY} />
            </S.CheckIcon>
          )}
        </>
      )}
      {error && showError && <FieldError>{error}</FieldError>}
    </FieldContainer>
  );
};
